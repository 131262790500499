<template>
  <div class="main">
    <div class="banner">
      <div
        class="img_box"
        style="display:flex;justify-content: center;overflow: hidden;"
      >
        <img src="../../assets/image//retail/retail.png" alt="" />
      </div>
      <div class="banner_info">
        <h3>新零售云店</h3>
        <p>
          助力传统零售业转型实现消费者、线上商城、实体门店三位一体的零售生态闭环
        </p>
        <div class="banner_info_btn"><button>立即咨询</button></div>
      </div>
    </div>
    <div class="retail_graphic">
      <h3>新零售模式图解</h3>
      <div class="retail_graphic_img">
        <img src="../../assets/image/retail_graphic.png" alt="" />
      </div>
    </div>
    <div class="retailers_wrap">
      <div class="retailers">
        <h3>电商方案</h3>
        <div class="retailers_header_nav">
          <ul>
            <li>
              <span
                @click="change(1)"
                :class="currentIndex == 1 ? 'active' : ''"
                >全渠道多终端覆盖</span
              >
            </li>
            <li>
              <span
                @click="change(2)"
                :class="currentIndex == 2 ? 'active' : ''"
                >线上+线下无缝结合</span
              >
            </li>
            <li>
              <span
                @click="change(3)"
                :class="currentIndex == 3 ? 'active' : ''"
                >打破消费场景限制</span
              >
            </li>
            <li>
              <span
                @click="change(4)"
                :class="currentIndex == 4 ? 'active' : ''"
                >精准数据化营销</span
              >
            </li>
          </ul>
        </div>
        <div class="retailers_con" v-show="currentIndex == 1">
          <div class="retailers_con_left">
            <h3>全渠道多终端覆盖</h3>
            <div class="retailers_con_left_btn">
              <button>快速引流获客</button>
            </div>
            <p>
              通过搭建电商平台，链接微商城、小程序、APP获取用户流量，利用拼团、分销等热门电商玩法获得更多用户。
            </p>
          </div>
          <div class="retailers_con_right">
            <img src="../../assets/image/retail_upgrade1.png" alt="" />
          </div>
        </div>
        <div class="retailers_con" v-show="currentIndex == 2">
          <div class="retailers_con_left">
            <h3>线上+线下无缝结合</h3>
            <div class="retailers_con_left_btn">
              <button>全面提升用户体验</button>
            </div>
            <p>
              消费者到门店购物，通过支付，扫码等留存用户信息，并吸引成为线上会员，在线上电商平台享受更多优惠，实现门店引流，线上营销，并满足消费者更多个性化需求。
            </p>
          </div>
          <div class="retailers_con_right">
            <img src="../../assets/image/retail_upgrade2.png" alt="" />
          </div>
        </div>
        <div class="retailers_con" v-show="currentIndex == 3">
          <div class="retailers_con_left">
            <h3>打破消费场景限制</h3>
            <div class="retailers_con_left_btn">
              <button>全面升级消费体验</button>
            </div>
            <p>
              新零售赋予了传统商家更多空间，让消费者通过电脑、手机在各个地方都可以在你的平台购物，并享受平台各类优惠促销活动，享受购物带来的诸多乐趣。
            </p>
          </div>
          <div class="retailers_con_right">
            <img src="../../assets/image/retail_upgrade3.png" alt="" />
          </div>
        </div>
        <div class="retailers_con" v-show="currentIndex == 4">
          <div class="retailers_con_left">
            <h3>精准数据化营销</h3>
            <div class="retailers_con_left_btn">
              <button>数据化精准营销</button>
            </div>
            <p>
              消费者在平台的购物及浏览，都会在后台生成智能统计报表，商品也会根据智能权重进行拍卖，让商家更好的判断每件商品的价值。
            </p>
          </div>
          <div class="retailers_con_right">
            <img src="../../assets/image/retail_upgrade4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="promotion_wrap">
      <h3>多元化的促销功能</h3>
      <div class="promotion_list">
        <li class="promotion_list_con">
          <div class="promotion_list_imgbox">
            <img src="../../assets/image/retail_icon1.png" alt="" />
          </div>
          <h4>满减满赠</h4>
          <p>满额即优惠，促进消费</p>
        </li>
        <li class="promotion_list_con">
          <div class="promotion_list_imgbox">
            <img src="../../assets/image/retail_icon2.png" alt="" />
          </div>
          <h4>限时折扣</h4>
          <p>限时进行商品促销打折</p>
        </li>
        <li class="promotion_list_con">
          <div class="promotion_list_imgbox">
            <img src="../../assets/image/retail_icon3.png" alt="" />
          </div>
          <h4>优惠卷</h4>
          <p>营销必备优惠利器</p>
        </li>
        <li class="promotion_list_con">
          <div class="promotion_list_imgbox">
            <img src="../../assets/image/retail_icon4.png" alt="" />
          </div>
          <h4>推荐有礼</h4>
          <p>推荐引流分成必备</p>
        </li>
      </div>
      <div class="promotion">
        <button>立即咨询</button>
      </div>
    </div>
    <div class="case_wrap">
      <h3>案例展示</h3>
      <div class="case_con">
        <div style="display: flex;position: relative;">
        <div class="case_con_img1">
          <img src="../../assets/image/community_case1.png" alt="" />
        </div>
        <div class="case_con_img2">
          <img src="../../assets/image/community_case2.png" alt="" />
        </div>
        <div class="case_con_info">
          <div>
            <h4>社区团购演示地址</h4>
            <p>社区团购小程序，独立部署，提供系统源码。</p>
            <div class="case_con_info_btn"><button>立即咨询</button></div>
          </div>
        </div>
        <div class="retail_case_how">
          <img src="../../assets/image/retail_case_how4.jpg" alt="">
          <img src="../../assets/image/retail_case_how2.jpg" alt="">
          <img src="../../assets/image/retail_case_how5.jpg" alt="">
          <img src="../../assets/image/retail_case_how1.jpg" alt="">
          <img src="../../assets/image/retail_case_how3.jpg" alt="">
        </div>
      </div>

      </div>

    </div>
    <div class="enterprise_wrap">
      <div class="enterprise">
        <h3>为企业赋能</h3>
        <div class="enterprise_header_nav">
          <ul>
            <!-- <li><span
                @click='enterpriseClick(1)'
                :class='enterpriseIndex==1?"active":""'
              >B2B2C系统</span></li> -->
            <li>
              <span
                @click="enterpriseClick(1)"
                :class="enterpriseIndex == 1 ? 'active' : ''"
                >新零售商城</span
              >
            </li>
            <li>
              <span
                @click="enterpriseClick(2)"
                :class="enterpriseIndex == 2 ? 'active' : ''"
                >技术服务支持</span
              >
            </li>
            <li>
              <span
                @click="enterpriseClick(3)"
                :class="enterpriseIndex == 3 ? 'active' : ''"
                >二次开发</span
              >
            </li>
          </ul>
        </div>
        <div class="enterprise_con" v-show="enterpriseIndex == 1" style="display:flex; margin-top: 37px;">
          <div class="B2B2CFormLeft">
            <ul>
              <li
                class="B2B2CFormLeft_li"
                v-for="item in B2CList"
                :key="item.index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="B2B2CFormRight">
            <ul style="display:flex;">
              <li class="B2B2CFormRight_column_li">
                <ul>
                  <li class="first_row_li">SAAS版</li>
                  <li
                    class="row_li"
                    v-for="item in b2cBasicEdition"
                    :key="item.index"
                  >
                    {{ item }}
                  </li>
                  <li class="last_row_li">
                    <el-button type="danger" round class="">立即咨询</el-button>
                  </li>
                </ul>
              </li>
              <li class="B2B2CFormRight_column_li">
                <ul>
                  <li class="first_row_li">独立部署版</li>
                  <li
                    class="row_li"
                    v-for="item in b2cStandardEdition"
                    :key="item.index"
                  >
                    {{ item }}
                  </li>
                  <li class="last_row_li">
                    <el-button type="danger" round class="">立即咨询</el-button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="enterprise_con" v-show="enterpriseIndex == 2">
          <ul class="enterprise_con_ul">
            <li
              class="enterprise_con_li"
              v-for="item in enterpriseList"
              :key="item.title"
            >
              <div class="con_li_img">
                <img :src="item.serviceIcon" alt="#" />
              </div>
              <div class="con_li_content">
                <p class="con_li_title">{{ item.title }}</p>
                <p class="con_li_text1">{{ item.text1 }}</p>
                <p class="con_li_text2">{{ item.text2 }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="enterprise_con" v-show="enterpriseIndex == 3">
          <ul class="develop_con_ul">
            <li
              class="develop_con_li"
              v-for="item in developList"
              :key="item.title"
            >
              <div class="con_li_img">
                <img :src="item.serviceIcon" alt="#" />
              </div>
              <div class="develop_li_content">
                <p class="develop_li_title">{{ item.title }}</p>
                <p class="develop_li_text">{{ item.text }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: 1,
      enterpriseIndex: 1,
      enterpriseList: [
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon01.png'),
          title: '系统初始化',
          text1: '产品的安装、部署、初始化',
          text2: '实现开发、生产环境一键安装',
        },
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon02.png'),
          title: '系统迁移',
          text1: '产品数据及相关配置的迁移',
          text2: '高效、便捷完成系统数据迁移',
        },
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon03.png'),
          title: '二次开发',
          text1: '根据需求对产品功能进行修改',
          text2: '快速定位并提供最佳解决方案 ',
        },
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon04.png'),
          title: '技术支持',
          text1: '对产品的使用提供技术支持服务',
          text2: '1V1为您解决技术难题',
        },
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon05.png'),
          title: '项目定制',
          text1: '针对企业更深层次的项目需求',
          text2: '快速实现个性化功能',
        },
        {
          serviceIcon: require('../../assets/image/retail/enterprise_icon06.png'),
          title: '专属客服',
          text1: '快速为您解决售前、售后问题',
          text2: '7*24小时在线客服',
        },
      ],
      developList: [
        {
          serviceIcon: require('../../assets/image/retail/develop_icon01.png'),
          title: '丰富的开发经验',
          text: '10年专注于电商软件的研发，为您提供专业的服务和高效的解决方案',
        },
        {
          serviceIcon: require('../../assets/image/retail/develop_icon02.png'),
          title: '开发时限有保障',
          text:
            '根据客户提供的功能需求，综合评估开发周期，保障客户项目进展高效、可控',
        },
        {
          serviceIcon: require('../../assets/image/retail/develop_icon03.png'),
          title: '实时响应效率高',
          text: '一对一专属定制客服，随时随地为您的电子商务平台保驾护航',
        },
        {
          serviceIcon: require('../../assets/image/retail/develop_icon04.png'),
          title: '自主性更强',
          text: '100%源代码支持，无任何源码加密，全面满足企业个性化需求',
        },
        {
          serviceIcon: require('../../assets/image/retail/develop_icon05.png'),
          title: '品质更优',
          text: '产品品质突出，无论从技术架构设计，还是从代码编写都是独具匠心',
        },
        {
          serviceIcon: require('../../assets/image/retail/develop_icon06.png'),
          title: '技术服务支持',
          text: '全国设有多个研发及服务中心，有效解决功能和操作上的各种疑问',
        },
      ],
      B2B2CList: [
        'B2B2C系统',
        '商业授权许可',
        '分销模块',
        '团购模块',
        '拼团模块',
        '直播模块',
        '积分模块',
        'PC支持',
        'H5支持',
        '微信公众号支持',
        '微信小程序支持',
        '支付宝小程序支持',
        '手机APP支持',
        '软件源代码',
        '软件代码注释',
        'JavaDoc开发文档',
        '数据库结构文档',
        '授权域名数量',
      ],
      B2CList: [
        '新零售商城',
        '商业授权许可',
        '营销模块',
        '门店模块',
        '会员模块',
        '充值模块',
        '积分模块',
        '自提模块',
        '可视化装修模块',
        '微信小程序支持',
        '微信公众号支持',
        '域名',
        '服务器',
        '价格',
        '价格说明',
      ],
      basicEdition: [
        '√',
        '√',
        '√',
        '√',
        '×',
        '√',
        '√',
        '√',
        '×',
        '×',
        '×',
        '×',
        '×',
        '×',
        '×',
        '×',
        '1',
      ],
      standardEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '×',
        '×',
        '×',
        '×',
        '×',
        '×',
        '1',
      ],
      advancedEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '可选',
        '√',
        '×',
        '×',
        '×',
        '1',
      ],
      developmentEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '可选',
        '√',
        '√',
        '√',
        '√',
        '1',
      ],
      b2cBasicEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '自备',
        '包含',
        '3999元/年',
        '当前价格不包含修图服务，如有需要另外付费',
      ],
      b2cStandardEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '自备',
        '自备',
        '5999元/年',
        '当前价格不包含修图服务，如有需要另外付费',
      ],
      b2cAdvancedEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '×',
        '×',
        '×',
        '1',
      ],
      b2cDevelopmentEdition: [
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '√',
        '1',
      ],
    };
  },
  methods: {
    change: function(index) {
      this.currentIndex = index;
    },
    enterpriseClick: function(index) {
      this.enterpriseIndex = index;
    },
  },
};
</script>
<style scoped>
@import './reatail.css';
</style>
<style scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner img {
  /* width: 100%; */
  height: 540px;
}
.banner_info h3 {
  font-size: 50px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.banner_info p {
  width: 360px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0 40px 0;
}
.banner_info_btn button {
  width: 185px;
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #979797;
  color: #ff0610;
}
.banner_info {
  max-width: 1200px;
  width: 1200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
}
.retail_graphic {
  max-width: 1200px;
  margin: auto;
}
.retail_graphic h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding: 70px 0 30px 0;
}
.retail_graphic img {
  width: 1100px;
}
.retailers_wrap {
  width: 100%;
  height: 600px;
  background: #f0f4fc;
  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
}
.retailers {
  max-width: 1200px;
  margin: auto;
}
/* .retailers_header{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D6E4FF;
  } */
.retailers h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.retailers_header_nav ul {
  display: flex;
  width: 100%;
}
.retailers_header_nav ul li {
  width: 25%;
  padding: 24px 0 18px 0;
  /* line-height: 80px; */
  /* border-bottom: 3px solid red; */
  cursor: pointer;
}
.retailers_header_nav ul li span {
  padding: 24px 0 18px 0;
  /* line-height: 80px; */
  /* border-bottom: 3px solid red; */
  cursor: pointer;
}
.retailers_con {
  background: #fff;
  padding: 0 40px;
  box-sizing: border-box;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.retailers_con_right {
  width: 45%;
  height: 410px;
  /* border: 2px solid #D6E4FF; */
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.retailers_con_left {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 110px 0;
  text-align: left;
}
.retailers_con_left h3 {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.retailers_con_left p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 20px;
}
.retailers_con_left_btn {
  width: 100%;
}
.retailers_con_left_btn button {
  width: 130px;
  height: 44px;
  border-radius: 2px;
  outline: 0;
  border: 0;
  cursor: pointer;
  background: #ff0610;
  color: #fff;
  margin-bottom: 10px;
  margin: 25px 0;
}
.retailers_con_left_btn button:hover {
  background: #f56c6c;
}
.retailers_con_right img {
  /* width: 100%;   */
  height: 410px;
  transform: scale(0.8);
}
.promotion_wrap {
  width: 1200px;
  margin: auto;
}
.promotion_wrap h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 40px 0 30px 0;
}
.promotion_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.promotion_list li {
  width: 24%;
  margin-right: 1.333%;
  height: 200px;
  border-radius: 2px;
  border: 1px solid #edeef0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.promotion_list li:nth-child(4n) {
  margin-right: 0px;
}
.promotion_list_con .promotion_list_imgbox {
  width: 72px;
  height: 72px;
}
.promotion_list_con .promotion_list_imgbox img {
  width: 100%;
  height: 100%;
}
.promotion_list_con h4 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 12px 0;
}
.promotion_list_con p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
}
.promotion button {
  width: 130px;
  height: 44px;
  background: #ff0610;
  border-radius: 2px;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.promotion button:hover {
  background: #f56c6c;
}
.promotion {
  margin-top: 40px;
  margin-bottom: 70px;
}
.case_wrap {
  width: 1200px;
  margin: auto;
}
.case_wrap h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-bottom: 30px;
}
.case_con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 95px;
  overflow: hidden;
}
.case_con .case_con_img1 {
  width: 292px;
  height: 596px;
  margin-right: 18px;
}
.case_con .case_con_img2 {
  width: 242px;
  height: 501px;
  margin-right: 64px;
}
.case_con .case_con_img1 img,
.case_con .case_con_img2 img {
  width: 100%;
  /* height: 100%; */
}
.case_con_info {
  width: 492px;
  /* height: 433px; */
  /* background: #fafafa; */
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 60px 0 50px;
  text-align: left;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
}
.case_con_info h4 {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.case_con_info p {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
  margin: 25px 0 40px 0;
}
.case_con_info_btn button {
  width: 130px;
  height: 44px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ff0000;
  color: #ff0610;
  cursor: pointer;
}
.case_con_info_btn button:hover {
  box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.7);
}
.retail_case_how{
  width: 405px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  bottom:97px;
  right: 48px;
}
.retail_case_how img{
  width:28%;
  margin: 10px;
  box-shadow:0px 0px 10px #c9c9c9;
}
.active {
  border-bottom: 3px solid #ff0610;
  color: #ff0610;
}
.enterprise_wrap {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 20px 30px 120px 30px;
}
.enterprise {
  max-width: 1200px;
  margin: auto;
}
.enterprise h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.enterprise_header_nav {
  margin-bottom: 20px;
}
.enterprise_header_nav ul {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d6e4ff;
}
.enterprise_header_nav ul li {
  width: 33%;
  cursor: pointer;
}
.enterprise_header_nav ul li span {
  width: 130px;
  padding: 24px 0 18px 0;
  cursor: pointer;
  display: inline-block;
  text-align: left;
}
.enterprise_con_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 253px;
}
.enterprise_con_li {
  width: 390px;
  height: 119px;
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #f1f3f5;
  padding: 20px;
  align-items: center;
}
.enterprise_con_li:hover {
  box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.5);
}
.con_li_img {
  width: 46px;
  height: 46px;
  overflow: hidden;
  margin-right: 15px;
}
.con_li_img img {
  width: 100%;
  height: 100%;
}
.con_li_content {
  height: 79px;
  text-align: left;
}
.con_li_title {
  height: 21px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #495057;
  line-height: 21px;
}
.con_li_text1 {
  height: 21px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868e96;
  line-height: 21px;
  margin-top: 8px;
}
.con_li_text2 {
  height: 21px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c92a2a;
  line-height: 21px;
  margin-top: 8px;
}
.develop_con_ul {
  height: 370px;
  display: flex;
  flex-wrap: wrap;
}
.develop_con_li {
  width: 400px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.develop_con_li:first-child {
  border-right: 1px solid #f1f3f5;
  border-bottom: 1px solid #f1f3f5;
}
.develop_con_li:nth-child(2) {
  border-right: 1px solid #f1f3f5;
  border-bottom: 1px solid #f1f3f5;
}
.develop_con_li:nth-child(3) {
  border-bottom: 1px solid #f1f3f5;
}
.develop_con_li:nth-child(4) {
  border-right: 1px solid #f1f3f5;
}
.develop_con_li:nth-child(5) {
  border-right: 1px solid #f1f3f5;
}
.develop_li_content {
  width: 296px;
  text-align: center;
}
.develop_li_title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #495057;
  line-height: 21px;
  margin: 8px 0;
}
.develop_li_text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868e96;
  line-height: 21px;
}
</style>
